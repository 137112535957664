<template>
  <div class="home-body home-4 contact-page">
    <div class="wrapper">
      <header class="header-area stick-h2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="logo floatleft">
                <a href="/">
                  <img
                    alt="GiveProudly - logo"
                    src="../assets/logo/giveProudly-log.svg"
                  />
                </a>
              </div>
              <div class="main-menu floatright">
                <nav>
                  <ul>
                    <li>
                      <a href="/" class="remove-link">HOME</a>
                    </li>
                    <li>
                      <a href="/about" class="remove-link">ABOUT</a>
                    </li>
                    <li>
                      <a href="/faq" class="remove-link">FAQ</a>
                    </li>
                    <li class="active remove-link">
                      <a href="/contact">CONTACT</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- mobile nav menu -->
      <div class="mobile-menu-area d-block d-lg-none">
        <div class="container">
          <div class="mobile-menu-area">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center py-3">
                  <nav>
                    <a class="remove-link mr-3" href="/">HOME </a>
                    <a class="remove-link mr-3" href="/about">ABOUT </a>
                    <a class="remove-link mr-3" href="/faq">FAQ </a>
                    <a class="active remove-link" href="/contact">CONTACT </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile-menu-area end -->
      <!-- hero area -->
      <!-- <section class="slider-main-area">
        <div class="main-slider">
          <div class="hero-slides-faq">
            <img
              src="https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMTUxNjQzNA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280"
              class="main-image"
              alt=""
              title="#slider-direction-1"
            />
            <div class="main-container">
              <div class="inner-container container-xl">
                <div class="slide-title">
                  <div class="section-title-contact">
                    <h1 class="title6">CONTACT US</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- contact form -->
      <!-- <section id="contact-area" class="map-area-two-text pt-160 pb-80">
        <div class="container">
          <div class="row mb-4">
            <div class="col-md-12 text-center">
              <div class="contact-heading">
                <p>
                  For more information, contact us using the form below and
                  we'll get right back to you.
                </p>
              </div>
            </div>
          </div>
          <div class="contact-form-area">
            <form
              id="contactForm"
              class="contact-form"
              type="submit"
              onsubmit="return false;"
            >
              <div
                class="
                  row
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                "
              >
                <div class="col-lg-6 contact-form-column">
                  <label>Name*</label>
                  <input
                    class="contact-form-control"
                    id="contactName"
                    required=""
                    placeholder="Your full name here"
                    type="text"
                    :v-model="userName"
                  />
                  <span
                    id="contactNameError"
                    class="error-message-span row"
                  ></span>
                </div>
                <div class="col-lg-6 contact-form-column">
                  <label>Email*</label>
                  <input
                    class="contact-form-control"
                    id="contactEmail"
                    required=""
                    placeholder="Your primary email address here"
                    type="email"
                    :v-model="userEmail"
                  />
                  <span
                    id="contactEmailError"
                    class="error-message-span row"
                  ></span>
                </div>
                <div class="col-lg-12 contact-form-column">
                  <label>Subject*</label>
                  <input
                    class="contact-form-control"
                    id="contactSubject"
                    required=""
                    placeholder="Your message subject here"
                    type="text"
                    :v-model="userSubject"
                  />
                  <span
                    id="contactSubjectError"
                    class="error-message-span row"
                  ></span>
                </div>
                <div class="col-lg-12 contact-form-column">
                  <div class="home-2-text-area">
                    <label>Message</label>
                    <textarea
                      class="contact-form-control"
                      id="contactMessage"
                      :v-model="userMessage"
                    ></textarea>
                  </div>
                </div>
                <div id="html_element"></div>
                <span id="grecaptchaError" class="error-message-span"></span>
                <br />
                <button class="submit" id="contactButton" type="submit">
                  Send Message
                </button>
              </div>
              <p class="form-messege"></p>
            </form>
          </div>
          <div id="contactModal" class="modal">
            <div class="modal-content">
              <span id="modalClose" class="close">&times;</span>
              <div class="submit-icon">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <h1>Thank you</h1>
              <p>
                The form was submitted successfully, we will get back to you
                shortly.
              </p>
            </div>
          </div>
        </div>
      </section> -->
      <template>
        <div class="main-slider">
          <div class="hero-slides-faq">
            <img
              src="@/assets/img/secondary-main.jpg"
              class="main-image"
              alt=""
              title="#slider-direction-1"
            />
            <div class="container">
              <div class="inner-container container-xl">
                <div class="slide-title">
                  <div class="section-title">
                    <h1 class="title6">CONTACT US</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <b-row> -->
        <div id="contact-area" class="map-area-two-text pt-160 pb-80">
          <div class="container">
            <div class="mb-4">
              <div class="col-md-12 text-center">
                <div class="contact-heading">
                  <p>
                    For more information, contact us using the form below and
                    we'll get right back to you.
                  </p>
                </div>
              </div>
            </div>
            <template v-if="!showNotification">
              <div class="form-container">
                <form
                  id="contactForm"
                  class="contact-form"
                  type="submit"
                  onsubmit="return false;"
                >
                  <div
                    class="row d-flex flex-row align-items-center justify-content-center"
                  >
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <b-col cols="12" lg="6" class="pr-lg-2">
                        <span>Name*</span>
                        <FormInput
                          groupId="user-name-group"
                          id="user-name-input"
                          class="text-muted-gray font-primary mt-2"
                          isRequired
                          trim
                          form="create-user-form"
                          type="text"
                          label="Full name"
                          :state="nameError.status"
                          :invalidFeedback="nameError.message"
                          v-model="name"
                        ></FormInput>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <span>Email*</span>
                        <FormInput
                          groupId="user-password-group"
                          id="user-password-input"
                          class="text-muted-gray font-primary mt-2"
                          isRequired
                          trim
                          form="create-user-form"
                          type="text"
                          label="Email address"
                          :state="emailError.status"
                          :invalidFeedback="emailError.message"
                          v-model="email"
                        ></FormInput>
                      </b-col>
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <b-col cols="12">
                        <span>Subject*</span>
                        <FormInput
                          groupId="user-name-group"
                          id="user-name-input"
                          class="text-muted-gray font-primary mt-2"
                          isRequired
                          trim
                          form="create-user-form"
                          type="text"
                          label="Subject"
                          :state="subjectError.status"
                          :invalidFeedback="subjectError.message"
                          v-model="subject"
                        ></FormInput>
                      </b-col>
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <b-col cols="12">
                        <span>Message</span>
                        <FormInput
                          groupId="user-name-group"
                          id="user-name-input"
                          class="text-muted-gray font-primary mt-2"
                          isRequired
                          trim
                          :isTextArea="true"
                          :rows="8"
                          form="create-user-form"
                          type="text"
                          label="Message"
                          :state="messageError.status"
                          :invalidFeedback="messageError.message"
                          v-model="message"
                        ></FormInput>
                      </b-col>
                    </b-row>
                    <div id="html_element"></div>
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onCaptchaVerified"
                      @expired="onCaptchaExpired"
                      sitekey="6Le-74whAAAAAEl0ERaNiHm30XedVQ57-V23js4V"
                    >
                    </vue-recaptcha>
                    <br />
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center w-100"
                      no-gutters
                    >
                      <b-col
                        class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                        cols="9"
                        sm="6"
                      >
                        <FormButton
                          isBlock
                          class="font-weight-normal text-white mt-2 border-dark bg-color"
                          type="submit"
                          @click.native="submitBtn"
                          :isLoading="isAPILoading"
                          :isDisabled="isAPILoading || !grecaptchaToken || !name || !email || !subject || !message"
                          >Send Message</FormButton
                        >
                      </b-col>
                    </b-row>
                  </div>
                  <p class="form-messege"></p>
                </form>
              </div>
            </template>
            <template v-if="showNotification">
              <template>
                <div class="modal-content">
                  <button
                    @click="closeBtn"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="submit-icon">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <h1>{{ notificationTitle }}</h1>
                  <p>
                    {{ notificationMessage }}
                  </p>
                </div>
              </template>
            </template>
          </div>
        </div>
        <!-- </b-row> -->
      </template>
      <!-- footer section -->
      <footer id="footer" class="social-area">
        <div class="footer-top">
          <div class="container-lg">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <h4 class="social-follow-title">Follow Us</h4>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <div class="social-icon floatright">
                  <ul>
                    <li>
                      <a
                        class="twitter"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="facebook"
                        target="_blank"
                        href="https://www.facebook.com/ThePersonalPACProject"
                      >
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="youtube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCAuoNhsEtra6ACv8leeEexg"
                      >
                        <i class="fa fa-youtube-square" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle ptb-40">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="middle-text">
                  <div class="social-logo">
                    <a href="/">
                      <img
                        class="logo-size"
                        src="../assets/logo/giveproudly-logo-white.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="middle-mgn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-lg">
            <div class="footer-bottom-all">
              <div class="row">
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"
                >
                  <div class="footer-menu">
                    <ul>
                      <li>
                        <a href="/" class="remove-link">HOME</a>
                      </li>
                      <li>
                        <a href="/about" class="remove-link">ABOUT</a>
                      </li>
                      <li>
                        <a href="/faq" class="remove-link">FAQ</a>
                      </li>
                      <li>
                        <a href="/contact" class="active remove-link"
                          >CONTACT</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"
                >
                  <div class="footer-menu right-side-menu">
                    <ul>
                      <li>
                        <a href="/privacy" class="remove-link">PRIVACY</a>
                      </li>
                      <li>
                        <a href="/terms" class="remove-link">T&amp;C</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<!-- jquery -->
<script src="js/vendor/jquery-1.12.4.min.js"></script>
<!-- Popper JS -->
<script src="js/popper.js"></script>
<!-- bootstrap JS -->
<script src="js/bootstrap.min.js"></script>
<!-- wow JS -->
<script src="js/wow.min.js"></script>
<!-- plugins JS -->
<script src="js/plugins.js"></script>
<!-- main JS -->
<script src="js/main.js"></script>
<!-- modernizr JS -->
<script src="js/vendor/modernizr-3.11.7.min.js"></script>

<script>
import "@/assets/styles/give-proudly/style.css";

// import "@/assets/styles/style.css";
import "@/assets/styles/animate.css";
import "@/assets/styles/bootstrap.min.css";
// import "@/assets/styles/chosen.min.css";
import "@/assets/styles/font-awesome.min.css";
import "@/assets/styles/magnific-popup.css";
import "@/assets/styles/main.css";
import "@/assets/styles/meanmenu.min.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/responsive.css";
// import "@/assets/styles/style-customizer.css";
// import "@/assets/styles/color-custom.css";
import "@/assets/styles/give-proudly/color-custom.css";

// components
import FormInput from "../Form/FormInput";
import FormButton from "../Form/FormButton";
// services
import { createMessage } from "@/services/contact.service";
// uitls
import CheckEmail from "@/util/CheckEmail";

export default {
  components: {
    FormInput,
    FormButton,
  },
  data() {
    return {
      // contactUrl: "https://staging.api.personalpac.us/contact",
      // userName: undefined,
      // userEmail: undefined,
      // userSubject: undefined,
      // userMessage: undefined,
      nameError: {
        status: null,
        message: "",
      },
      name: null,
      emailError: {
        status: null,
        message: "",
      },
      email: null,
      subjectError: {
        status: null,
        message: "",
      },
      subject: null,
      messageError: {
        status: null,
        message: "",
      },
      message: null,
      isAPILoading: false,
      isReset: false,
      showNotification: false,
      notificationTitle : "",
      notificationMessage : "",
      grecaptchaToken : undefined
    };
  },
  mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //   "src",
    //   "https://www.google.com/recaptcha/enterprise.js?render=explicit"
    // );
    // document.body.appendChild(recaptchaScript);
    // recaptchaScript.addEventListener("load", scriptLoaded, false);
    // function scriptLoaded() {
    //   window.grecaptcha.enterprise.render("html_element", {
    //     sitekey: "6LeQVsEcAAAAAMZVsm0t9UhnFqiSTash4sam4Oy6",
    //   });
    // }
    // // modal functions
    // contactModalClose.onclick = function () {
    //   contactModal.style.display = "none";
    //   resetForm();
    // };
    // window.onclick = function (event) {
    //   if (event.target == contactModal) {
    //     contactModal.style.display = "none";
    //     resetForm();
    //   }
    // };
  },
  watch: {
    name(value) {
      if (!this.isReset) {
        this.nameError.status = null;
        if (value) {
          this.nameError.status = true;
        } else {
          this.nameError.status = false;
          this.nameError.message = "Please fill out this field";
        }
      }
    },
    email(value) {
      if (!this.isReset) {
        this.emailError.status = null;
        if (value) {
          if (CheckEmail(value) == true) this.emailError.status = true;
          else {
            this.emailError.status = false;
            this.emailError.message = "Please fill in a valid email address";
          }
        } else {
          this.emailError.status = false;
          this.emailError.message = "Please fill out this field";
        }
      }
    },
    subject(value) {
      if (!this.isReset) {
        this.subjectError.status = null;
        if (value) {
          this.subjectError.status = true;
        } else {
          this.subjectError.status = false;
          this.subjectError.message = "Please fill out this field";
        }
      }
    },
    message(value) {
      if (!this.isReset) {
        this.messageError.status = null;
        if (value) {
          this.messageError.status = true;
        } else {
          this.messageError.status = false;
          this.messageError.message = "Please fill out this field";
        }
      }
    },
  },
  methods: {
    async submitBtn() {
      let payload = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        token: this.grecaptchaToken,
      };
      // try {
      this.isAPILoading = true;
      //   let { data } = createMessage(payload);
      //   if (data.code == 200 && data.message == "success") {
      //     let payloadNotify = {
      //       isToast: true,
      //       title: "Contact Us",
      //       content: "The details has been added successfully",
      //       variant: "success",
      //     };
      //     this.$store.dispatch("notification/setNotify", payloadNotify);
      //     this.resetFn();
      //   } else {
      //   }
      // } catch (error) {
      //   console.log("Catch Error:", error);
      // } finally {
      //   this.isAPILoading = false;
      createMessage(payload)
        .then((res) => {
          if (res.data.code == 200 || res.data.message == "success") {
            this.notificationTitle = "Thank you !"
            this.notificationMessage = "The message was submitted successfully, we will get back to you shortly."
          }else{
            this.notificationTitle = "Sorry."
            this.notificationMessage = "We could not send your message. Please try again later."
          }
          this.showNotification = true;
          this.isAPILoading = false;
          this.resetFn();
        })
        .catch(() => console.log("ERROR CO01"));
      // }
    },
    onCaptchaVerified(recaptchaToken) {
      console.log("verified",recaptchaToken)
      this.grecaptchaToken = recaptchaToken;
      console.log("verified",this.grecaptchaToken,this.isAPILoading)

    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    resetFn() {
      this.isReset = true;
      this.name = null;
      this.email = null;
      this.subject = null;
      this.message = null;
      this.nameError.status = null;
      this.emailError.status = null;
      this.subjectError.status = null;
      this.messageError.status = null;
    },
    closeBtn() {
      this.showNotification = false;
    },
    // -------------------------------------------------- old code --------------------------------
    async sendEmail(payload) {
      // stringify the payload and call the API
      return fetch(contactUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((result) => {
          // do something with the result
          return result.status == 200;
        })
        .catch((err) => {
          // if any error occured, then catch it here
          console.error(err);
          return false;
        });
    },

    // check for errors
    checkErrors(
      userName,
      userEmail,
      userSubject,
      userMessage,
      grecaptchaToken
    ) {
      let noErrors = true;

      // get error span elements
      var userNameError = document.getElementById("contactNameError");
      var userEmailError = document.getElementById("contactEmailError");
      var userSubjectError = document.getElementById("contactSubjectError");
      var grecaptchaError = document.getElementById("grecaptchaError");

      if (userName.value == "") {
        // add error state to input
        userName.classList.add("error-state");

        // show error message
        userNameError.innerText = "Please fill in your full name here";

        noErrors = false;
      } else {
        // clear the error state/message
        userName.classList.remove("error-state");
        userNameError.innerText = "";
      }

      if (userEmail.value == "") {
        // add error state to input
        userEmail.classList.add("error-state");

        // show error message
        userEmailError.innerText =
          "Please fill in your primary email address here";

        noErrors = false;
      } else {
        // clear the error state/message
        userEmail.classList.remove("error-state");
        userEmailError.innerText = "";
      }

      if (userSubject.value == "") {
        // add error state to input
        userSubject.classList.add("error-state");

        // show error message
        userSubjectError.innerText = "Please fill in your message subject here";

        noErrors = false;
      } else {
        // clear the error state/message
        userSubject.classList.remove("error-state");
        userSubjectError.innerText = "";
      }

      if (
        grecaptchaToken == null ||
        grecaptchaToken == undefined ||
        !grecaptchaToken
      ) {
        grecaptchaError.innerText = "Please complete the reCAPTCHA";

        noErrors = false;
      } else {
        // clear the error message
        grecaptchaError.innerText = "";
      }

      // if all validations pass
      return noErrors;
    },
  },

  // reset form
  resetForm() {
    var userName = document.forms["contactForm"]["contactName"];
    var userNameError = document.getElementById("contactNameError");
    var userEmail = document.forms["contactForm"]["contactEmail"];
    var userEmailError = document.getElementById("contactEmailError");
    var userSubject = document.forms["contactForm"]["contactSubject"];
    var userSubjectError = document.getElementById("contactSubjectError");
    var userMessage = document.forms["contactForm"]["contactMessage"];
    var grecaptchaError = document.getElementById("grecaptchaError");

    // clear all the inputs
    userName.value = "";
    userEmail.value = "";
    userSubject.value = "";
    userMessage.value = "";

    // clear all the inputs' error state

    userName.classList.remove("error-state");
    userEmail.classList.remove("error-state");
    userSubject.classList.remove("error-state");
    userMessage.classList.remove("error-state");

    // clear all the error messages
    userNameError.innerText = "";
    userEmailError.innerText = "";
    userSubjectError.innerText = "";
    grecaptchaError.innerText = "";
  },

  onContactButton() {
    var contactModal = document.getElementById("contactModal");
    var contactButton = document.getElementById("contactButton");
    var contactModalClose = document.getElementById("modalClose");
    grecaptcha.enterprise.ready(function () {
      // get the recaptcha
      const grecaptchaToken = grecaptcha.enterprise.getResponse();

      // send the request to BE
      var userName = document.forms["contactForm"]["contactName"];
      var userEmail = document.forms["contactForm"]["contactEmail"];
      var userSubject = document.forms["contactForm"]["contactSubject"];
      var userMessage = document.forms["contactForm"]["contactMessage"];

      // check for errors
      if (
        checkErrors(
          userName,
          userEmail,
          userSubject,
          userMessage,
          grecaptchaToken
        )
      ) {
        // append the button loader
        contactButton.innerHTML = '<div class="loader-small"></div>';
        // prepare the payload with the input values
        let payload = {
          name: userName.value,
          email: userEmail.value,
          subject: userSubject.value,
          message: userMessage.value,
          token: grecaptchaToken,
        };
        // send email if no errors are detected
        sendEmail(payload).then((result) => {
          if (result) {
            // reset google recapthca
            grecaptcha.enterprise.reset();
            // if result is true show the modal
            contactModal.style.display = "block";
            // once result is success, remove the loader and replace with text
            contactButton.innerHTML = "Send Message";
          }
        });
      } else {
        contactButton.innerHTML = "Send Message";
      }
    });
  },
};
</script>
<style scoped>
.logo-size {
  object-fit: cover;
  height: 200px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.bg-color {
  background: #00aff2 !important;
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 15px 30px;
  border: none;
  width: 30%;
  text-align: center;
  /* Inhamul: Custom CSS */
  min-width: 300px;
}
.remove-link {
  text-decoration: none;
  color: white;
  border: none !important;
}
.mobile-menu {
  height: 60px;
}
.active {
  color: black;
  text-decoration: underline;
}
</style>
